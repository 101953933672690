import React, { useEffect, useState } from "react"

import SEO from "../components/seo"
import Layout from "../components/Layout"
import Slider from "../components/Slider"
import SliderMobile from "../components/Slider_mobile"

import { IsMobileDevice } from "../utils"
import { FooterHome } from "../components/FooterHome/FooterHome"
import Footer from "../components/Footer/Footer"
import ReactPlayer from "react-player"
import { FooterSunsetstrip } from "../components/FooterSunsetstip/FooterSunsetstrip"
import AgeGateBoolean from "../components/AgeGate/AgegateBoolean"

const JJOO = ({ data, location }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [mobileLight, setMobileLight] = useState(false)
  const [age, setAge] = useState(true)
  const videoRef = React.createRef()

  useEffect(() => {
    let local = localStorage.getItem("age") || sessionStorage.getItem("age")
    if (local) setAge(false)
    setIsMobile(IsMobileDevice())
    setMobileLight(IsMobileDevice())
  }, [])

  const switchState = data?.allPopUpSwitch?.edges[0]?.node?.status
  return !age ? (
    <Layout mobileLight={mobileLight} isMobile={isMobile}>
      <SEO title="Corona Island" />
      <ReactPlayer
        ref={videoRef}
        style={{ backgroundColor: "#000" }}
        controls
        height={"100vh"}
        width={"100vw"}
        url={
          "https://www.youtube.com/watch?v=_z-_5YZyPfU"
        }
      />
      <FooterSunsetstrip />
    </Layout>
  ) : (
    <AgeGateBoolean location={location} setAge={setAge} />
  )
}

export const query = graphql`
  query {
    allPopUpSwitch {
      edges {
        node {
          id
          status
        }
      }
    }
  }
`

export default JJOO
