import React from "react"
import styles from "./footersunsetstrip.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export function FooterSunsetstrip() {
  const sendFooterAnalytics = () => {
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "footer",
      event_action: "Click",
      event_label: "Herencia",
      interaction: "True",
      component_name: "",
      element_text: "",
    })
  }
  return (
    <div className={styles.footerHome}>
      <Container>
        <Row className={styles.row}>
          <Col xs={6} md={2} className={"text-center"}>
            <a
              href={"https://www.instagram.com/corona_argentina"}
              onClick={sendFooterAnalytics}
            >
              Contacto
            </a>
          </Col>
          <Col xs={6} md={2} className={"text-center"}>
            <a href={"/TyCCervezaCorona.pdf"} onClick={sendFooterAnalytics}>
              términos y condiciones
            </a>
          </Col>
          <Col xs={6} md={2} className={"text-center"}>
            <a href={"ppdp.pdf"} onClick={sendFooterAnalytics}>
              política de privacidad
            </a>
          </Col>
          <Col xs={6} md={2} className={"text-center"}>
            <a
              href={
                "https://www.cerveceriaymalteriaquilmes.com/sustentabilidad-consumo-responsable-anterior/"
              }
              onClick={sendFooterAnalytics}
            >
              consumo responsable
            </a>
          </Col>
          <Col xs={12} md={3} className={"text-center"}>
            <a
              href={"https://www.cerveceriaymalteriaquilmes.com/"}
              onClick={sendFooterAnalytics}
            >
              INFORMACIÓN OFICIAL DE LA COMPAÑÍA
            </a>
          </Col>
        </Row>
        <Row className={styles.row2}>
          <Col className={"text-center"}>
            BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO
            COMPARTA EL CONTENIDO CON MENORES. ®️2021 Corona - Buenos Aires -
            Argentina. Todos los derechos reservados.
          </Col>
        </Row>
      </Container>
    </div>
  )
}
