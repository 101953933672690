import React from "react"
import facebook from "../../images/Facebook.png"
import insta from "../../images/Instagram.png"
import styles from "./footer.module.scss"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import { use100vh } from 'react-div-100vh'

const Footer = ({ anywhere, ...props }) => {
  const height = use100vh()
  const halfHeight = height ? height * 0.6 : '60vh'
  return anywhere ? (
    <div
      className={
        props.positionRelative
          ? styles.footerWhitPositionRelative
          : styles.footer
      }
      style={{height:halfHeight}}
    >
      <div className={styles.containersocialmedia}>
        <Link to="https://www.facebook.com/cervezacorona">
          <img src={facebook} alt="" className={styles.tamanoredes} />
        </Link>
        <Link to="https://instagram.com/corona_argentina?igshid=oxhoy01vemn0">
          <img src={insta} alt="" className={styles.tamanoredes} />
        </Link>
      </div>
      <div>
        <p className={styles.textfooter}>
          No se quede conectado si comparte el dispositivo con menores. <br />
          BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO
          COMPARTA EL CONTENIDO CON MENORES. @2021 Corona - Buenos Aires -
          Argentina. Todos los derechos reservados.
        </p>
      </div>
      <div className="d-flex justify-content-center ml-4">
        <a
          href={"/TyCCervezaCorona.pdf"}
          download
          target="blank"
          className="mr-3"
        >
          <h6 className={styles.texttermandcond}>TÉRMINOS DE USO</h6>
        </a>
        <h6 className={styles.texttermandcond}>|</h6>
        <a href={"/PP_AO.pdf"} download target="blank" className="ml-3">
          <h6 className={styles.texttermandcond}>POLÍTICAS DE PRIVACIDAD</h6>
        </a>
        <h6 className={styles.texttermandcondlast + " ml-3"}>|</h6>
        <a href={"/BBCC-ANY.pdf"} download target="blank" className="ml-3">
          <h6 className={styles.texttermandcondlast}>BASES Y CONDICIONES</h6>
        </a>
        <h6 className={styles.texttermandcondlast + " ml-3"}>|</h6>
        <a
          href={
            "https://www.cerveceriaymalteriaquilmes.com/sustentabilidad-consumo-responsable-anterior/"
          }
          target="blank"
          className="ml-3"
        >
          <h6 className={styles.texttermandcondlast}>consumo responsable</h6>
        </a>
        <h6 className={styles.texttermandcondlast + " ml-3"}>|</h6>
        <a href={"/PREGUNTAS_FRECUENTES.pdf"} target="blank" className="ml-3">
          <h6 className={styles.texttermandcondlast}>preguntas frecuentes</h6>
        </a>
      </div>
      {/* Mobile */}
      <div className={"d-flex justify-content-center " + styles.footeritemlast}>
        <a href={"/BBCC-ANY.pdf"} download target="blank" className="ml-3">
          <h6 className={styles.texttermandcond}>BASES Y CONDICIONES</h6>
        </a>
        <h6 className={styles.texttermandcond + " ml-3"}>|</h6>
        <a
          href={
            "https://www.cerveceriaymalteriaquilmes.com/sustentabilidad-consumo-responsable-anterior/"
          }
          target="blank"
          className="ml-3"
        >
          <h6 className={styles.texttermandcond}>consumo responsable</h6>
        </a>
      </div>
      <div className={"d-flex justify-content-center " + styles.footeritemlast}>
        <a href={"/PREGUNTAS_FRECUENTES.pdf"} target="blank" className="ml-3">
          <h6 className={styles.texttermandcond}>preguntas frecuentes</h6>
        </a>
      </div>
    </div>
  ) : (
    <div
      className={
        props.positionRelative
          ? styles.footerWhitPositionRelative
          : styles.footer
      }
    >
      <div className={styles.containersocialmedia}>
        <Link to="https://www.facebook.com/cervezacorona">
          <img src={facebook} alt="" className={styles.tamanoredes} />
        </Link>
        <Link to="https://instagram.com/corona_argentina?igshid=oxhoy01vemn0">
          <img src={insta} alt="" className={styles.tamanoredes} />
        </Link>
      </div>
      <div>
        <p className={styles.textfooter}>
          No se quede conectado si comparte el dispositivo con menores. <br />
          BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO
          COMPARTA EL CONTENIDO CON MENORES. @2021 Corona - Buenos Aires -
          Argentina. Todos los derechos reservados.
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <a
          href={"/TyCCervezaCorona.pdf"}
          download
          target="blank"
          className="mr-3"
        >
          <h6 className={styles.texttermandcond}>TÉRMINOS DE USO</h6>
        </a>
        <h6 className={styles.texttermandcond}>|</h6>
        <a href={"/PP_AO.pdf"} download target="blank" className="ml-3">
          <h6 className={styles.texttermandcond}>POLÍTICAS DE PRIVACIDAD</h6>
        </a>
        <h6 className={styles.texttermandcondlast + " ml-3"}>|</h6>
        <a href={"/BBCC-ANY.pdf"} download target="blank" className="ml-3">
          <h6 className={styles.texttermandcondlast}>BASES Y CONDICIONES</h6>
        </a>
        <h6 className={styles.texttermandcondlast + " ml-3"}>|</h6>
        <a
          href={
            "https://www.cerveceriaymalteriaquilmes.com/sustentabilidad-consumo-responsable-anterior/"
          }
          target="blank"
          className="ml-3"
        >
          <h6 className={styles.texttermandcondlast}>consumo responsable</h6>
        </a>
      </div>
      <div className={"d-flex justify-content-center " + styles.footeritemlast}>
        <a href={"/BBCC.pdf"} download target="blank" className="ml-3">
          <h6 className={styles.texttermandcond}>BASES Y CONDICIONES</h6>
        </a>
        <h6 className={styles.texttermandcond + " ml-3"}>|</h6>
        <a
          href={
            "https://www.cerveceriaymalteriaquilmes.com/sustentabilidad-consumo-responsable-anterior/"
          }
          target="blank"
          className="ml-3"
        >
          <h6 className={styles.texttermandcond}>consumo responsable</h6>
        </a>
      </div>
    </div>
  )
}

export default Footer
